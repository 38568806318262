import * as React from "react";
const ContactMeHref = React.lazy(() => import("./Contact"));

export interface IContactMeGateProps {}

// Make user click a button to show email adderss via lazy loading
export const ContactMeGate: React.FC<IContactMeGateProps> = props => {
  const [showingEmail, setShowingEmail] = React.useState(false);

  let email = showingEmail ? (
    <ContactMeHref />
  ) : (
    <div
      className="App-contact"
      onClick={() => setShowingEmail(true)}
    >
      Click for contact info
    </div>
  );
  return (
    <React.Suspense fallback={<div>loading...</div>}>
      {email}
    </React.Suspense>
  );
};

export default ContactMeGate;
